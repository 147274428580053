import React from "react";
import { ContentRoute } from "../../_metronic/layout";
import DashboardPage from "../pages/DashboardPage";
import Profile from "../../_metronic/components/SuperAdmin/Profile/Profile";
import Anfragedetails from "../../_metronic/components/AnfrageDetails/Anfragedetails";
import Stepper from "../../_metronic/components/Stepper/Stepper";
import News from "../../_metronic/components/News/News";
import Kontaktdetails from "../../_metronic/components/Kontaktdetails/Kontaktdetails";
import Adminmitteilungen from "../../_metronic/components/mitteilungenAdmin";
import VattenfallLeads from "../../_metronic/components/vattenfallLeads";
import ProjectTrello from "../../_metronic/components/ProjektTrello";
import GoogleMap from "../../_metronic/components/GoogleMap";
import Project from "../../_metronic/components/ProjectName/Project";
import WattFoxDataModel from "../../_metronic/components/WattFoxFunnelData";
import WattFoxLaufliste from "../../_metronic/components/WattfoxLaufliste";
import VattenfallLaufles from "../../_metronic/components/VattenfallLaufls";
import VattenFallLeadData from "../../_metronic/components/VattenFallLeadData/index.jsx";
import VattenfallLeadsMesse from "../../_metronic/components/vattenfallMesse/index.jsx";
import VattenfallPromoLeads from "../../_metronic/components/vattenfallPromo/index.jsx";
import CredentialManagement from "../../_metronic/components/CredentialManagement/CredentialManagement.jsx";
import FunnelResponse2 from "../../_metronic/components/FunnelResponse2/FunnelResponse2.jsx";
import ProjeckteWp from "../../_metronic/components/prokectWp";

const AdminRoleRoutes = () => {
  return (
    <>
      <ContentRoute exact path="/dashboard" component={DashboardPage} />
      <ContentRoute exact path="/profile" component={Profile} />
      <ContentRoute exact path="/anfrange" component={Anfragedetails} />
      <ContentRoute exact path="/stepper" component={Stepper} />
      <ContentRoute exact path="/news" component={News} />
      <ContentRoute exact path="/kontakt" component={Kontaktdetails} />
      <ContentRoute exact path="/funnel2" component={FunnelResponse2} />
      <ContentRoute exact path="/mitteilungen" component={Adminmitteilungen} />
      <ContentRoute exact path="/vattenfall" component={VattenfallLeads} />
      <ContentRoute
        exact
        path="/vattenfallmesse"
        component={VattenfallLeadsMesse}
      />
      <ContentRoute
        exact
        path="/vattenfallpromo"
        component={VattenfallPromoLeads}
      />
      <ContentRoute exact path="/credential" component={CredentialManagement} />
      <ContentRoute exact path="/projekte-trello" component={ProjectTrello} />
      <ContentRoute exact path="/google-map" component={GoogleMap} />
      <ContentRoute exact path="/project" component={Project} />
      <ContentRoute exact path="/wattfox-data" component={WattFoxDataModel} />
      <ContentRoute
        exact
        path="/vattenfall-data"
        component={VattenFallLeadData}
      />
      <ContentRoute
        exact
        path="/vattenfall-laufles"
        component={VattenfallLaufles}
      />
      <ContentRoute
        exact
        path="/wattfox-laufles"
        component={WattFoxLaufliste}
      />
      <ContentRoute exact path="/projektewp" component={ProjeckteWp} />
    </>
  );
};

export default AdminRoleRoutes;
