import React from "react";

const UploadIconR = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 64 64"
    >
      <path
        d="M0 0 C1.67083819 1.32810215 3.33729713 2.66172696 5 4 C7.16231892 4.35109346 7.16231892 4.35109346 9.5 4.4375 C13.25064753 4.68754317 13.8888107 4.90965869 17 7.4375 C19 10 19 10 20 12 C21.11375 12.433125 22.2275 12.86625 23.375 13.3125 C27.34517383 15.16068437 28.44529173 15.97604918 30 20 C30.48807148 23.35549146 30.73108696 25.74790855 28.98046875 28.74609375 C26.82724109 31.45877029 25.31181941 32.8960602 22 34 C20.59175107 34.08021999 19.18002769 34.10765977 17.76953125 34.09765625 C16.96708984 34.09443359 16.16464844 34.09121094 15.33789062 34.08789062 C14.50451172 34.07951172 13.67113281 34.07113281 12.8125 34.0625 C11.96751953 34.05798828 11.12253906 34.05347656 10.25195312 34.04882812 C8.16793845 34.03705403 6.08396023 34.0191189 4 34 C4 38.62 4 43.24 4 48 C-3.71484375 48.09765625 -3.71484375 48.09765625 -6 48 C-7 47 -7 47 -7.09765625 44.05859375 C-7.08605469 42.86363281 -7.07445313 41.66867188 -7.0625 40.4375 C-7.05347656 39.23996094 -7.04445313 38.04242188 -7.03515625 36.80859375 C-7.02355469 35.88175781 -7.01195313 34.95492187 -7 34 C-7.99580078 34.05220703 -7.99580078 34.05220703 -9.01171875 34.10546875 C-18.3881795 34.39292229 -18.3881795 34.39292229 -22 31 C-24.36380554 27.03490684 -25.12392742 24.91396471 -24.5 20.3125 C-22.83002569 16.62464007 -21.38989122 15.14968712 -18 13 C-17.01 12.67 -16.02 12.34 -15 12 C-14.95875 10.948125 -14.9175 9.89625 -14.875 8.8125 C-13.98024272 4.91391472 -13.05991429 3.24031038 -9.8125 0.8828125 C-6.31564558 -0.82039676 -3.85684144 -0.55097735 0 0 Z "
        fill="#b1a8a8"
        transform="translate(28,8)"
      />
      <path
        d="M0 0 C4.17087411 1.39029137 5.18208698 4.27843718 7.1875 7.9375 C7.55552734 8.58396484 7.92355469 9.23042969 8.30273438 9.89648438 C11 14.71553224 11 14.71553224 11 17 C9.35 17 7.7 17 6 17 C6 22.94 6 28.88 6 35 C-1.71484375 35.09765625 -1.71484375 35.09765625 -4 35 C-5 34 -5 34 -5.1328125 31.19921875 C-5.13023438 30.04035156 -5.12765625 28.88148437 -5.125 27.6875 C-5.12886719 25.96080078 -5.12886719 25.96080078 -5.1328125 24.19921875 C-5.02438878 21.58748263 -4.77059738 19.47692016 -4 17 C-5.65 17 -7.3 17 -9 17 C-8.43310319 12.6852854 -6.88294703 9.86348525 -4.5 6.25 C-3.55640625 4.80367187 -3.55640625 4.80367187 -2.59375 3.328125 C-1 1 -1 1 0 0 Z "
        fill="#006037"
        transform="translate(26,21)"
      />
      <path
        d="M0 0 C4.07661299 1.358871 4.60930409 3.30542948 6.6875 7 C7.31011719 8.093125 7.93273437 9.18625 8.57421875 10.3125 C10 13 10 13 10 14 C0.595 14.495 0.595 14.495 -9 15 C-2.97321429 3.75 -2.97321429 3.75 0 0 Z "
        fill="#006037"
        transform="translate(26,21)"
      />
      <path
        d="M0 0 C2.31 0 4.62 0 7 0 C7 4.95 7 9.9 7 15 C4.36 15 1.72 15 -1 15 C-1.02715383 13.25008666 -1.04646551 11.50005055 -1.0625 9.75 C-1.07410156 8.77546875 -1.08570312 7.8009375 -1.09765625 6.796875 C-1.01195577 4.34241322 -0.70706066 2.33831086 0 0 Z "
        fill="#006037"
        transform="translate(22,38)"
      />
      <path
        d="M0 0 C0.66 0.33 1.32 0.66 2 1 C2.42968661 5.29686609 2.41369759 8.01214548 -0.3125 11.375 C-3.63555862 14.62077819 -5.76763033 15.12930759 -10.23046875 15.09765625 C-11.03291016 15.09443359 -11.83535156 15.09121094 -12.66210938 15.08789062 C-13.49548828 15.07951172 -14.32886719 15.07113281 -15.1875 15.0625 C-16.4549707 15.05573242 -16.4549707 15.05573242 -17.74804688 15.04882812 C-19.83206155 15.03705403 -21.91603977 15.0191189 -24 15 C-24 14.34 -24 13.68 -24 13 C-22.75863281 12.98582031 -21.51726563 12.97164062 -20.23828125 12.95703125 C-18.59631757 12.910118 -16.95439163 12.86187068 -15.3125 12.8125 C-14.49587891 12.80669922 -13.67925781 12.80089844 -12.83789062 12.79492188 C-8.49294795 12.63660413 -6.52200563 12.3982311 -2.96875 9.6875 C-0.58208815 6.42951716 -0.23464687 3.98899685 0 0 Z "
        fill="#b1a8a8"
        transform="translate(56,27)"
      />
    </svg>
  );
};

export default UploadIconR;
