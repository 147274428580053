/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { getUserInfo } from "../../../../../utils/user.util";
import "./sideMenu.scss";
import { ApiGet } from "../../../../../helpers/API/ApiData";
import useDebounce from "../../../../../hooks/useDebounceHook";
import SuperAdminMenu from "./superAdminMenu";
import VattenFallAdminMenu from "./vattenfallAdmin";
import LeadAdminMenu from "./leadAdminMenu";
import AdminWpMenu from "./adminWpMenu";
import OldVattenFallLeadMenu from "./oldVattenfallAdmin";

export function AsideMenuList({ layoutProps }) {
  const [id, setId] = useState();
  const [isOpenKitsList, setIsOpenKitsList] = useState(false);
  const [isOpenKitsListsec, setIsOpenKitsListsec] = useState(false);
  const [user, setUser] = useState([]);
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  let userInfo = getUserInfo();

  const debouncedValue = useDebounce(search, 300);

  const getPVBTrueUser = async () => {
    try {
      setLoader(true);
      const response = await ApiGet(
        `user/getPVVertriebUser${
          debouncedValue ? `?search=${debouncedValue}` : ""
        }`
      );
      setUser(response?.data?.payload?.findUser);
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoader(false);
    }
  };

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${
          !hasSubmenu && "menu-item-active"
        } menu-item-open menu-item-not-hightlighted`
      : "";
  };

  useEffect(() => {
    getPVBTrueUser();
  }, [debouncedValue]);

  return (
    <>
      {userInfo?.role === "admin" && (
        <SuperAdminMenu
          isOpenKitsList={isOpenKitsList}
          setIsOpenKitsList={setIsOpenKitsList}
          user={user}
          setId={setId}
          id={id}
          loader={loader}
          setSearch={setSearch}
          search={search}
          layoutProps={layoutProps}
          getMenuItemActive={getMenuItemActive}
          setIsOpenKitsListsec={setIsOpenKitsListsec}
          isOpenKitsListsec={isOpenKitsListsec}
        />
      )}

      {userInfo?.role === "vattenfallAdmin" && (
        <VattenFallAdminMenu
          isOpenKitsList={isOpenKitsList}
          setIsOpenKitsList={setIsOpenKitsList}
          user={user}
          setId={setId}
          id={id}
          loader={loader}
          setSearch={setSearch}
          search={search}
          layoutProps={layoutProps}
          getMenuItemActive={getMenuItemActive}
        />
      )}
      {userInfo?.role === "leadAdmin" && (
        <LeadAdminMenu
          setId={setId}
          id={id}
          layoutProps={layoutProps}
          getMenuItemActive={getMenuItemActive}
        />
      )}
      {userInfo?.role === "admin-WP" && (
        <AdminWpMenu
          isOpenKitsList={isOpenKitsList}
          setIsOpenKitsList={setIsOpenKitsList}
          user={user}
          setId={setId}
          id={id}
          loader={loader}
          setSearch={setSearch}
          search={search}
          layoutProps={layoutProps}
          getMenuItemActive={getMenuItemActive}
          setIsOpenKitsListsec={setIsOpenKitsListsec}
          isOpenKitsListsec={isOpenKitsListsec}
        />
      )}
      {userInfo?.role === "old-vattenfall" && (
        <OldVattenFallLeadMenu
          isOpenKitsList={isOpenKitsList}
          setIsOpenKitsList={setIsOpenKitsList}
          user={user}
          setId={setId}
          id={id}
          loader={loader}
          setSearch={setSearch}
          search={search}
          layoutProps={layoutProps}
          getMenuItemActive={getMenuItemActive}
          setIsOpenKitsListsec={setIsOpenKitsListsec}
          isOpenKitsListsec={isOpenKitsListsec}
        />
      )}
    </>
  );
}
