import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "./CredentialManagement.scss";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";

import { customStyles } from "../tableStyle";
import { TailSpin } from "react-loader-spinner";
import { ValidateForm } from "./ValidateForm";
import { toast } from "react-toastify";
import useDebounce from "../../../hooks/useDebounceHook";
import Moment from "react-moment";
import Swal from "sweetalert2";
import { Trash } from "react-feather";
import { Button } from "reactstrap";
import AddUserDetails from "./AddUserDetails";
import { Modal } from "react-bootstrap";
import { useOnChange } from "../../../hooks/onChangeHooks";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CredentialManagement.scss";
import Select from "react-select";

export default function CredentialManagement() {
  const [getNewsData, setGetNewsData] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [isAddUser, setIsAddUser] = useState(false);
  const [fileType, setfileType] = useState("");
  const [page, setPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [FormType, setFormType] = useState("");
  const [loading, setLoading] = useState(false);
  const [UpdatePassword, setUpdatePassword] = useState(false);
  const [buttonLoading, setbuttonloading] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 900);
  const [image, setImage] = useState("");
  const [newimage, setNewImage] = useState("");
  const [close, setClose] = useState(false);
  const [newClose, setNewClose] = useState(false);
  const [uploadImage, setUploadImage] = useState("");
  const [isActive, setISActive] = useState();
  const [status, setStatus] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [checked, setChecked] = useState(false);
  const [projectList, setProjectList] = useState();
  const [salesPatner, setSalesPatner] = useState();
  const [VattenfallsalesPatner, setVattenfallSalesPartner] = useState();
  const [selectedPatner, setSelectedSalesPatner] = useState([]);
  const [allowLead, setAllowLead] = useState(false);
  const [selectedVattenfallPartner, setSelectedVattenfallPartner] = useState(
    []
  );
  const [vattenfallChecked, setVattenfallChecked] = useState(false);
  const [teamLeadCheck, setTeamLeadCheck] = useState(true);
  const [projectName, setProjectOptions] = useState();
  const [project, setProject] = useState();
  const [WPproject, setWpProject] = useState(false);

  const { inputValue, setInputValue, errors, setErrors, handleChange } =
    useOnChange({});
  const handleImage = (item) => {
    setImage(item);
    setClose(!close);
  };
  const handleSignature = (items) => {
    setNewImage(items);
    const extension = items.split(".").pop().toLowerCase();

    setfileType(extension);
    if (
      extension == "image" ||
      extension == "pdf" ||
      extension == "png" ||
      extension == "svg" ||
      extension == "jpeg" ||
      extension == "jpg"
    ) {
      setNewClose(!close);
    }
  };
  const columns = [
    {
      name: "S.No",
      cell: (row, index) => {
        return <p>{(page - 1) * countPerPage + index + 1}</p>;
      },
      width: "60px",
    },
    {
      name: "Aktion",
      selector: (row) => (
        <div className="actionColumn">
          <Button
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => updateNewsData(row)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="pencilIcon"
              aria-hidden="true"
              width="20"
              height="20"
            >
              <path d="M3 17.25V21h3.75l11.06-11.06-3.75-3.75L3 17.25zM22.71 5.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.996.996 0 00-1.41 0L16.34 3.34l3.75 3.75 2.62-2.62z" />
            </svg>
          </Button>
          <button
            onClick={() => handleConfirmDelete(row)}
            className={`btn btn  ${row?.isActive ? "sucessfull" : "danger"}`}
          >
            <span>{row?.isActive ? "aktiv" : "inactiv"}</span>
          </button>
        </div>
      ),
    },
    {
      minWidth: "200px",

      name: "Unique Id",
      selector: (row) => (
        <div className="profileImage">{row?.userId ? row?.userId : "-"}</div>
      ),
    },
    {
      maxWidth: "50px",

      name: "Lead",
      selector: (row) => (
        <div className="profileImage">
          {row?.straperCount ? row?.straperCount : "-"}
        </div>
      ),
    },
    {
      minWidth: "200px",
      name: "Benutzername",
      selector: (row) => (
        <div className="profileImage">{row?.name ? row?.name : "-"}</div>
      ),
    },
    {
      minWidth: "200px",

      name: "Unique Tracking Id",
      selector: (row) => (
        <div className="profileImage">
          {row?.uniqueTrackingId ? row?.uniqueTrackingId : "-"}
        </div>
      ),
    },

    {
      minWidth: "200px",

      name: "Vergütung/ Lead",
      selector: (row) => (
        <div className="profileImage">
          {row?.compensation_Lead ? row?.compensation_Lead : "-"}
        </div>
      ),
    },
    {
      minWidth: "200px",

      name: "Vergütung/ Team",
      selector: (row) => (
        <div className="profileImage">
          {row?.compensation_Team ? row?.compensation_Team : "-"}
        </div>
      ),
    },

    {
      minWidth: "200px",
      name: "Vorname",
      selector: (row) => (
        <div className="profileImage">{row?.vorname ? row?.vorname : "-"}</div>
      ),
    },
    {
      minWidth: "200px",
      name: "Nachname",
      selector: (row) => (
        <div className="profileImage">
          {row?.nachname ? row?.nachname : "-"}
        </div>
      ),
    },

    {
      minWidth: "200px",

      name: "E-mail",
      selector: (row) => <div className="profileImage">{row?.email}</div>,
    },
    {
      minWidth: "200px",
      name: "Projekt",
      selector: (row) => <div className="profileImage">{row?.projectName}</div>,
    },
    {
      minWidth: "200px",
      name: "Leads",
      selector: (row) => (
        <div className="profileImage">
          {row?.userLead ? row?.userLead : "-"}
        </div>
      ),
    },
    {
      minWidth: "200px",
      name: "Geburtstag",
      selector: (row) => (
        <div className="profileImage">
          {row?.birthday ? (
            <Moment format="DD-MM-YYYY" date={row?.birthday} />
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      minWidth: "200px",
      name: "Telefon",
      selector: (row) => (
        <div className="profileImage">{row?.phone ? row?.phone : "-"}</div>
      ),
    },
    {
      minWidth: "200px",
      name: "Straße und Hausnummer",
      selector: (row) => (
        <div className="profileImage">
          {row?.streetHouseNumber ? row?.streetHouseNumber : "-"}
        </div>
      ),
    },
    {
      minWidth: "200px",
      name: "Postleitzahl",
      selector: (row) => (
        <div className="profileImage">
          {row?.postalCode ? row?.postalCode : "-"}
        </div>
      ),
    },
    {
      minWidth: "200px",
      name: "Ort",
      selector: (row) => (
        <div className="profileImage">
          {row?.location ? row?.location : "-"}
        </div>
      ),
    },
    {
      minWidth: "200px",
      name: "IBAN",
      selector: (row) => (
        <div className="profileImage">{row?.iban ? row?.iban : "-"}</div>
      ),
    },
    {
      minWidth: "200px",
      name: "Kontoinhaber",
      selector: (row) => (
        <div className="profileImage">
          {row?.accountOwner ? row?.accountOwner : "-"}
        </div>
      ),
    },
    {
      minWidth: "200px",
      name: "Kreditinstitut",
      selector: (row) => (
        <div className="profileImage">
          {row?.creditInstitution ? row?.creditInstitution : "-"}
        </div>
      ),
    },

    {
      minWidth: "200px",
      name: "Steuernummer",
      selector: (row) => (
        <div className="profileImage">
          {row?.steuernummer ? row?.steuernummer : "-"}
        </div>
      ),
    },
    {
      minWidth: "200px",
      name: "Geschlecht",
      selector: (row) => (
        <div className="profileImage">{row?.gender ? row?.gender : "-"}</div>
      ),
    },
    {
      minWidth: "250px",
      name: "Vertriebspartner",
      selector: (row) => (
        <div className="profileImage" style={{ "white-space": "normal" }}>
          {row?.patners.length > 0
            ? row?.patners?.map((item) => item?.name).join(", ")
            : "-"}
        </div>
      ),
    },
    {
      name: "Birthday",
      width: "210px",
      selector: (row) => (
        <div className="profileImage">
          {row.birthday ? (
            <Moment format="DD-MM-YYYY" date={row?.birthday} />
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      name: "Telefon",
      width: "210px",
      selector: (row) => (
        <div className="profileImage">{row.phone ? row.phone : "-"}</div>
      ),
    },
    {
      name: "Straße und Hausnummer",
      width: "250px",
      selector: (row) => (
        <div className="profileImage">
          {row.streetHouseNumber ? row.streetHouseNumber : "-"}
        </div>
      ),
    },
    {
      name: "Ort",
      width: "150px",
      selector: (row) => (
        <div className="profileImage">{row.location ? row.location : "-"}</div>
      ),
    },
    {
      name: "Postleitzahl",
      width: "100px",
      selector: (row) => (
        <div className="profileImage">
          {row.postalCode ? row.postalCode : "-"}
        </div>
      ),
    },
    {
      name: "Kreditinstitut",
      width: "200px",
      selector: (row) => (
        <div className="profileImage">
          {row.creditInstitution ? row.creditInstitution : ""}
        </div>
      ),
    },
    {
      name: "signature",
      width: "200px",
      selector: (row) => (
        <div
          className="profileImage"
          style={{ cursor: "pointer" }}
          onClick={() => handleSignature(row?.signature)}
        >
          {row?.signature == "null" || !row?.signature ? "-" : "view"}
        </div>
      ),
    },
    {
      name: "profileImage",
      width: "200px",
      selector: (row) => (
        <div
          className="profileImage"
          style={{ cursor: "pointer" }}
          onClick={() => handleImage(row?.profileImage)}
        >
          {row?.profileImage ? "view" : "-"}
        </div>
      ),
    },

    {
      name: "Datum",
      width: "210px",
      selector: (row) => (
        <div className="profileImage">
          {row.createdAt ? (
            <Moment format="DD-MM-YYYY" date={row?.createdAt} />
          ) : (
            "-"
          )}
        </div>
      ),
    },
  ];

  const handleAddNewsClose = () => {
    setIsAddUser(false);
    setInputValue({});
    setErrors({});
    setFormType("add");
    setUpdatePassword(false);
    setbuttonloading(false);
    setUploadImage("");
    setVattenfallChecked(false);
    setAllowLead(false);
    setSelectedVattenfallPartner([]);
    setVattenfallSalesPartner();
    setSalesPatner();
    setSelectedSalesPatner();
    setChecked(false);
    setWpProject(false);
  };

  const handleOnChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const updateNewsData = async (row) => {
    const joinedArray = row?.projectName?.split(",");
    const mappedArray = joinedArray?.map((item, index) => {
      const data = {
        value: index + 1,
        label: item,
      };
      return data;
    });

    setInputValue({
      name: row?.name,
      vorname: row?.vorname,
      nachname: row?.nachname,
      email: row?.email,
      id: row?._id,
      projectName: mappedArray,
      userLead: row?.userLead,
      birthday: row?.birthday,
      phone: row?.phone,
      streetHouseNumber: row?.streetHouseNumber,
      postalCode: row?.postalCode,
      iban: row?.iban,
      creditInstitution: row?.creditInstitution,
      accountOwner: row?.accountOwner,
      gender: row?.gender,
      uniqueTrackingId: row?.uniqueTrackingId,
      patners: row?.patners?.map((item) => {
        const data = {
          value: item?._id,
          label: item?.name,
        };
        return data;
      }),
      location: row?.location,
      signature: row?.signature ? row?.signature : "",
      profileImage: row?.profileImage ? row?.profileImage : "",
      compensation_Team: row?.compensation_Team ? row?.compensation_Team : "",
      compensation_Lead: row?.compensation_Lead ? row?.compensation_Lead : "",
      userId: row?.userId,
      steuernummer: row?.steuernummer,
      vattenfallPatners: row?.vattenfallPatners?.map((item) => {
        const data = {
          value: item?._id,
          label: item?.name,
        };
        return data;
      }),
    });
    setChecked(row?.PVVertrieb);
    setVattenfallChecked(row?.vattenfallPatnersFlag);
    setAllowLead(row?.allowLead);
    setTeamLeadCheck(row?.team_leader);
    setIsAddUser(true);
    setWpProject(row?.wpprojekte);
    // const datas= row?.patners?.map((item) => {
    //     const data = {
    //       value: item?._id,
    //       label: item?.name,
    //     };
    //     return data;
    //   });
    //   setSelectedSalesPatner(datas)
    setFormType("update");
  };

  const handleValidateForm = () => {
    const error = ValidateForm(inputValue, FormType, UpdatePassword);

    setErrors(error?.error);
    return error?.isValid;
  };

  const handleAddUser = () => {
    if (handleValidateForm()) {
      setLoading(true);
      let formData = new FormData();
      formData.append("name", inputValue?.name ? inputValue?.name : "");
      formData.append("email", inputValue?.email ? inputValue?.email : "");
      formData.append(
        "steuernummer",
        inputValue?.steuernummer ? inputValue?.steuernummer : ""
      );
      formData.append(
        "password",
        inputValue?.password ? inputValue?.password : ""
      );

      const projectArray = inputValue?.projectName?.map((item) => item.label);
      formData.append("projectName", projectArray?.join(","));
      formData.append(
        "uniqueTrackingId",
        inputValue?.uniqueTrackingId ? inputValue?.uniqueTrackingId : ""
      );
      let data =
        inputValue?.patners?.length > 0 &&
        inputValue?.patners?.map((ele, i) => {
          formData.append(`patners[${i}]`, ele?.value);
        });
      formData.append(
        "userLead",
        inputValue?.userLead ? inputValue?.userLead : ""
      );
      formData.append(
        "compensation_Team",
        inputValue?.compensation_Team ? inputValue?.compensation_Team : ""
      );
      formData.append(
        "compensation_Lead",
        inputValue?.compensation_Lead ? inputValue?.compensation_Lead : ""
      );

      formData.append(
        "birthday",
        inputValue?.birthday ? inputValue?.birthday : ""
      );
      formData.append("phone", inputValue?.phone ? inputValue?.phone : "");
      formData.append(
        "streetHouseNumber",
        inputValue?.streetHouseNumber ? inputValue?.streetHouseNumber : ""
      );
      formData.append(
        "postalCode",
        inputValue?.postalCode ? inputValue?.postalCode : ""
      );
      formData.append(
        "location",
        inputValue?.location ? inputValue?.location : ""
      );
      formData.append("iban", inputValue?.iban ? inputValue?.iban : "");
      formData.append(
        "creditInstitution",
        inputValue?.creditInstitution ? inputValue?.creditInstitution : ""
      );
      formData.append(
        "accountOwner",
        inputValue?.accountOwner ? inputValue?.accountOwner : ""
      );
      formData.append(
        "vorname",
        inputValue?.vorname ? inputValue?.vorname : ""
      );
      formData.append(
        "nachname",
        inputValue?.nachname ? inputValue?.nachname : ""
      );
      formData.append(
        "signature",
        inputValue?.signature ? inputValue?.signature : null
      );
      formData.append("PVVertrieb", checked);
      formData.append("allowLead", allowLead);
      formData.append("team_leader", teamLeadCheck);
      formData.append("wpprojekte", WPproject);

      let newData =
        inputValue?.vattenfallPatners?.length > 0 &&
        inputValue?.vattenfallPatners?.map((ele, i) => {
          if (ele?.value) {
            formData.append(`vattenfallPatners[${i}]`, ele?.value);
          }
        });

      formData.append("vattenfallPatnersFlag", vattenfallChecked || false);
      formData.append(
        "profileImage",
        inputValue?.profileImage ? inputValue?.profileImage : null
      );
      formData.append("gender", inputValue?.gender ? inputValue?.gender : "");

      ApiPost(`user/signup`, formData)
        .then((res) => {
          toast.success("Benutzer erfolgreich hinzugefügt");
          handleAddNewsClose();
          setLoading(false);
          handleGetNewsList();
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          setLoading(false);
          handleAddNewsClose();
        });
    }
  };

  const handleUpdateUser = (row) => {
    if (handleValidateForm()) {
      let formData = new FormData();

      formData.append("name", inputValue?.name ? inputValue?.name : "");
      formData.append("allowLead", allowLead);
      formData.append(
        "steuernummer",
        inputValue?.steuernummer ? inputValue?.steuernummer : ""
      );
      formData.append("PLZ[]", []);
      formData.append("email", inputValue?.email ? inputValue?.email : "");
      const projectArray = inputValue?.projectName?.map((item) => item.label);
      formData.append("projectName", projectArray?.join(","));
      formData.append(
        "uniqueTrackingId",
        inputValue?.uniqueTrackingId ? inputValue?.uniqueTrackingId : ""
      );
      let data =
        inputValue?.patners.length > 0
          ? inputValue?.patners?.map((ele, i) => {
              if (ele?.value) {
                formData.append(`patners[${i}]`, ele?.value);
              }
            })
          : formData.append(`patners[]`, "");

      let newData =
        inputValue?.vattenfallPatners?.length > 0 &&
        inputValue?.vattenfallPatners?.map((ele, i) => {
          if (ele?.value) {
            formData.append(`vattenfallPatners[${i}]`, ele?.value);
          }
        });

      formData.append(
        "userLead",
        inputValue?.userLead ? inputValue?.userLead : ""
      );
      formData.append(
        "birthday",
        inputValue?.birthday ? inputValue?.birthday : ""
      );
      formData.append("PVVertrieb", checked || false);
      formData.append("phone", inputValue?.phone ? inputValue?.phone : "");
      formData.append(
        "streetHouseNumber",
        inputValue?.streetHouseNumber ? inputValue?.streetHouseNumber : ""
      );
      formData.append(
        "postalCode",
        inputValue?.postalCode ? inputValue?.postalCode : ""
      );
      formData.append(
        "location",
        inputValue?.location ? inputValue?.location : ""
      );
      formData.append("iban", inputValue?.iban ? inputValue?.iban : "");
      formData.append(
        "creditInstitution",
        inputValue?.creditInstitution ? inputValue?.creditInstitution : ""
      );
      formData.append(
        "accountOwner",
        inputValue?.accountOwner ? inputValue?.accountOwner : ""
      );
      formData.append(
        "vorname",
        inputValue?.vorname ? inputValue?.vorname : ""
      );
      formData.append(
        "nachname",
        inputValue?.nachname ? inputValue?.nachname : ""
      );
      formData.append(
        "signature",
        inputValue?.signature ? inputValue?.signature : null
      );
      formData.append(
        "profileImage",
        inputValue?.profileImage ? inputValue?.profileImage : null
      );
      formData.append(
        "compensation_Team",
        inputValue?.compensation_Team ? inputValue?.compensation_Team : ""
      );
      formData.append(
        "compensation_Lead",
        inputValue?.compensation_Lead ? inputValue?.compensation_Lead : ""
      );
      formData.append("gender", inputValue?.gender ? inputValue?.gender : "");
      formData.append("vattenfallPatnersFlag", vattenfallChecked || false);
      formData.append("team_leader", teamLeadCheck);
      formData.append("wpprojekte", WPproject);
      inputValue?.password?.trim() &&
        formData.append("password", inputValue?.password?.trim());
      setbuttonloading(true);

      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      ApiPut(`user/update?id=${inputValue?.id}`, formData)
        .then((res) => {
          toast.success("Benutzer erfolgreich aktualisiert");
          setbuttonloading(false);
          handleAddNewsClose();
          setSelectedSalesPatner("");
          handleGetNewsList();
          setUploadImage("");
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          setbuttonloading(false);
        });
    }
  };

  const handleDeleteNewsInfo = async (row) => {
    setLoading(true);
    let response = false;
    if (row?.isActive) {
      await ApiDelete(`user/deleteUser?id=${row?._id}`)
        .then((res) => {
          setLoading(false);
          handleGetNewsList();
          response = true;
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      await ApiPut(`user/reactivateUser?id=${row?._id}`)
        .then((res) => {
          setLoading(false);
          handleGetNewsList();
          response = true;
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    return response;
  };

  const handleConfirmDelete = (row) => {
    return Swal.fire({
      title: "Bist du dir sicher?",
      text: "Deaktivierung bestätigen",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ja, deaktivieren",
      cancelButtonText: "Abbrechen",
      customClass: {
        confirmButton: "btn btn-primary mr-10",
        cancelButton: "btn btn-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async function (result) {
      if (result?.value) {
        const res = await handleDeleteNewsInfo(row);

        if (res) {
          Swal.fire({
            icon: "success",
            title: "Gelöscht!",
            text: "Vertriebspartner gelöscht.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        } else {
          Swal.fire({
            title: "Abgbrochen",
            text: "Deaktivierung abgebrochen!",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Abgbrochen",
          text: "Deaktivierung abgebrochen!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    });
  };
  const formatDate = (date) => {
    if (!date) return "";
    const adjustedDate = new Date(
      date.getTime() + Math.abs(date.getTimezoneOffset() * 60000)
    );
    return adjustedDate.toISOString().split("T")[0];
  };
  const handleGetNewsList = async () => {
    setLoading(true);
    await ApiGet(
      `user/get?page=${page}${
        debouncedSearchValue ? `&search=${searchValue}` : ""
      }&limit=${countPerPage}${isActive ? `&isActive=${isActive?.value}` : ""}${
        status ? `&status=${status?.value}` : ""
      }${startDate ? `&startDate=${formatDate(startDate)}` : ""}${
        endDate ? `&endDate=${formatDate(endDate)}` : ""
      }${status ? `&sortBy=straperCount` : ""}${status ? `&sortOrder=-1` : ""}${
        project ? `&projectName=${encodeURIComponent(project?.label)}` : ""
      }`
    )
      .then((response) => {
        const data = response?.data?.payload.data;
        setCount(response?.data?.payload.count);

        const activeData = data.filter((jobData) => {
          return jobData.roleId._id == "65fc2112e8fe93f708e798c3";
        });
        setGetNewsData(activeData);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetNewsList();
  }, [
    debouncedSearchValue,
    page,
    countPerPage,
    isActive,
    status,
    startDate,
    endDate,
    project,
  ]);
  const statusData = [
    {
      label: "inaktiv",
      value: false,
    },
    {
      label: "aktiv ",
      value: true,
    },
  ];

  const statusOptions = [
    {
      value: "Open",
      label: "offen",
    },
    {
      value: "Confirmed",
      label: "bestätigt",
    },
    {
      value: "Rejected",
      label: "abgelehnt",
    },
    {
      value: "null",
      label: "eingereicht",
    },
  ];
  const ProjectStyle = {
    control: (provided, state) => ({
      ...provided,
      maxWidth: "150px",
      fontSize: "12px",
      marginLeft: "30px",
      border: state.isFocused ? "2px solid #73AA85 !important" : "",
      boxShadow: "1px solid #73AA85 !important",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "13px",
      color: state.isSelected ? "white" : "black", // Change selected color here
      backgroundColor: state.isSelected ? "#73AA85" : "white",
      "&:hover": {
        backgroundColor: "#73AA85",
      },
    }),
    menu: (provided) => ({
      ...provided,
      maxWidth: "150px",
      marginLeft: "30px",
      zIndex: "999",
    }),
  };
  const handleOnChanges = (data) => {
    setISActive(data);
  };

  const handleOnNewChanges = (data) => {
    setStatus(data);
  };
  const handleProjektList = async () => {
    await ApiGet(`project/get`)
      .then((response) => {
        let projectedData = response?.data?.payload.data.map((item) => {
          let data = {
            value: item?._id,
            label: item?.projectname,
          };
          return data;
        });
        setProjectList(projectedData);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    handleProjektList();
  }, []);

  const handleOnNewProjectChanges = (data) => {
    setProject(data);
  };
  return (
    <div>
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3 row-alignment-top">
            <div className="col-12 col-lg-3 d-flex align-items-center Vertriebspartner-alignment">
              <h2 className="pl-3 pt-2 new-head-design">Vertriebspartner</h2>
              <div className="filter-user">
                <Select
                  className="dropdown-align"
                  value={isActive}
                  onChange={handleOnChanges}
                  options={statusData}
                  maxHeight={"100px"}
                  styles={ProjectStyle}
                  placeholder="Status"
                  isClearable
                />
              </div>
              <div className="filter-user">
                <Select
                  className="dropdown-align-pp"
                  value={status}
                  onChange={handleOnNewChanges}
                  options={statusOptions}
                  maxHeight={"100px"}
                  styles={ProjectStyle}
                  placeholder="Lead Status"
                  isClearable
                />
              </div>
              <div className="filter-user">
                <Select
                  className="dropdown-align-pp"
                  value={project}
                  onChange={handleOnNewProjectChanges}
                  options={projectList}
                  maxHeight={"100px"}
                  styles={ProjectStyle}
                  placeholder="Projekt"
                  isClearable
                />
              </div>
            </div>
            <div className="col-lg-2 newlgclass" style={{ zIndex: "9" }}>
              <div className={` reactDatePicker new-dates`}>
                <DatePicker
                  // selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Datum"
                  maxDate={new Date()}
                  onChange={handleOnChange}
                  onKeyDown={(e) => e.preventDefault()}
                  selectsRange
                  fixedHeight
                  className="new-picker"
                />
                {(startDate || endDate) && (
                  <div className="icons-design">
                    <i
                      class="fa fa-window-close"
                      aria-hidden="true"
                      onClick={() => {
                        setStartDate("");
                        setEndDate("");
                      }}
                    ></i>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-7 col-md-5 justify-content-end align-items-center row suche-button-input-flx-main">
              <div className="col col-lg-5">
                <div>
                  <input
                    type="search"
                    className={`form-control form-control-lg form-control-solid  custom-input`}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      setLoading(true);
                    }}
                    name="search"
                    value={searchValue}
                    placeholder="Suche"
                  />
                </div>
              </div>
              <div className="filter-button-mobile">
                <div className="filter-user">
                  <Select
                    className="dropdown-align"
                    value={isActive}
                    onChange={handleOnChanges}
                    options={statusData}
                    maxHeight={"100px"}
                    styles={ProjectStyle}
                    placeholder="Status"
                    isClearable
                  />
                </div>

                <div className="cus-medium-button-style button-height pr-2 mt-lg-0 cusbutton-moblie">
                  <button
                    onClick={() => {
                      setIsAddUser(true);
                      setFormType("add");
                    }}
                    className="btn btn addbutton"
                    style={{ display: "flex", gap: "5px" }}
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 800 800"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_52_17)">
                        <path
                          d="M799.999 323.698V476.302C799.999 487.789 790.691 497.111 779.189 497.111H497.112V779.188C497.112 790.692 487.79 799.998 476.303 799.998H323.699C312.219 799.998 302.89 790.69 302.89 779.188V497.111H20.8106C9.31362 497.111 0.000976562 487.789 0.000976562 476.302V323.698C0.000976562 312.208 9.31362 302.889 20.8106 302.889H302.89V20.8096C302.89 9.30806 312.216 0 323.699 0H476.303C487.79 0 497.112 9.30806 497.112 20.8096V302.889H779.189C790.693 302.889 799.999 312.208 799.999 323.698Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_52_17">
                          <rect width="800" height="800" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    Vertriebspartner
                  </button>
                </div>
              </div>
              <div className="filter-user filter-user-sc">
                <Select
                  className="dropdown-align-pp"
                  value={project}
                  onChange={handleOnNewProjectChanges}
                  options={projectList}
                  maxHeight={"100px"}
                  styles={ProjectStyle}
                  placeholder="Projekt"
                  isClearable
                />
              </div>
            </div>
          </div>
          <DataTable
            data={getNewsData}
            columns={columns}
            responsive
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            className="new_data__table table_height"
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[5, 10, 20, 25, 50]}
            paginationDefaultPage={page}
            progressPending={loading}
            progressComponent={
              <TailSpin color="#334D52" height={30} width={30} />
            }
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
            fixedHeader
          />
        </div>
        <Modal
          show={isAddUser}
          onHide={handleAddNewsClose}
          className="d-flex justify-content-center align-items-center"
        >
          <AddUserDetails
            UpdatePassword={UpdatePassword}
            setUpdatePassword={setUpdatePassword}
            inputValue={inputValue}
            handleChange={handleChange}
            errors={errors}
            handleAddNews={
              FormType === "add" ? handleAddUser : handleUpdateUser
            }
            setInputValue={setInputValue}
            setSalesPatner={setSalesPatner}
            VattenfallsalesPatner={VattenfallsalesPatner}
            setVattenfallSalesPartner={setVattenfallSalesPartner}
            salesPatner={salesPatner}
            formType={FormType}
            loading={loading}
            handleOnClose={handleAddNewsClose}
            buttonLoading={buttonLoading}
            getNewsData={getNewsData}
            setErrors={setErrors}
            setUploadImage={setUploadImage}
            uploadImage={uploadImage}
            setChecked={setChecked}
            checked={checked}
            setSelectedSalesPatner={setSelectedSalesPatner}
            selectedPatner={selectedPatner}
            vattenfallChecked={vattenfallChecked}
            setVattenfallChecked={setVattenfallChecked}
            setSelectedVattenfallPartner={setSelectedVattenfallPartner}
            selectedVattenfallPartner={selectedVattenfallPartner}
            setAllowLead={setAllowLead}
            allowLead={allowLead}
            teamLeadCheck={teamLeadCheck}
            setTeamLeadCheck={setTeamLeadCheck}
            setProjectOptions={setProjectOptions}
            projectName={projectName}
            WPproject={WPproject}
            setWpProject={setWpProject}
          />
        </Modal>
      </div>
      {close && (
        <div className="new__imagemodel">
          <div className="body_model"></div>
          <div className="new-white-box">
            <div className="header_close">
              <i
                onClick={() => {
                  setClose(!close);
                  setImage("");
                }}
                className="fas fa-window-close modalClose"
              ></i>
            </div>
            <div className="image_body">
              <img className="modal-image" src={image} />
            </div>
          </div>
        </div>
      )}

      {newClose && (
        <div className="new__imagemodel">
          <div className="body_model"></div>
          <div className="new-white-box">
            <div className="header_close">
              <i
                onClick={() => {
                  setNewClose(!newClose);
                  setNewImage("");
                }}
                className="fas fa-window-close modalClose"
              ></i>
            </div>
            <div className="image_body">
              {fileType == "svg" ||
              fileType == "png" ||
              fileType == "jpg" ||
              fileType == "jpeg" ? (
                <>
                  <img className="modal-image" src={newimage} />
                </>
              ) : fileType === "pdf" ? (
                <>
                  <object
                    data={newimage}
                    type="application/pdf"
                    width="420px"
                    height="650px"
                  ></object>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
