import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../_metronic/layout";
import { getUserInfo } from "../utils/user.util";
import AdminRoleRoutes from "./allRoutes/adminRoleRoutes.js";
import VattenFallAdminRoutes from "./allRoutes/vattenFallAdminRoutes.js";
import LeadAdminRoutes from "./allRoutes/leadAdminRoutes.js";
import AdminWPRoutes from "./allRoutes/adminWpRoutes.js";
import OldVattenFallRoutes from "./allRoutes/oldVattenfallRoutes.js";

export default function BasePage() {
  let userInfo = getUserInfo();
  return (
    <>
      {userInfo.role === "admin" ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <AdminRoleRoutes />
          </Switch>
        </Suspense>
      ) : userInfo.role === "vattenfallAdmin" ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <VattenFallAdminRoutes />
          </Switch>
        </Suspense>
      ) : userInfo.role == "leadAdmin" ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <LeadAdminRoutes />
          </Switch>
        </Suspense>
      ) : userInfo.role == "admin-WP" ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <Switch>
            <Redirect exact from="/" to="/projektewp" />
            <AdminWPRoutes />
          </Switch>
        </Suspense>
      ) : userInfo.role == "old-vattenfall" ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <OldVattenFallRoutes />
          </Switch>
        </Suspense>
      ) : (
        <Suspense fallback={<LayoutSplashScreen />}>
          <Switch>
            <Redirect to="error/error-v6" />
          </Switch>
        </Suspense>
      )}
    </>
  );
}
