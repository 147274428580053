import React from "react";

const EyeIcon = ({ onClick }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="20.000000pt"
      height="20.000000pt"
      viewBox="0 0 24.000000 24.000000"
      preserveAspectRatio="xMidYMid meet"
      onClick={onClick}
    >
      <g
        transform="translate(0.000000,20.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M63 180 c-64 -38 -64 -82 0 -120 40 -25 74 -25 114 0 42 25 57 54 43
81 -15 27 -69 59 -100 59 -14 0 -39 -9 -57 -20z m91 -26 c31 -30 9 -84 -34
-84 -10 0 -26 7 -34 16 -31 30 -9 84 34 84 10 0 26 -7 34 -16z"
        />
        <path d="M94 136 c-10 -26 4 -48 28 -44 33 4 33 52 0 56 -13 2 -25 -3 -28 -12z" />
      </g>
    </svg>
  );
};

export default EyeIcon;
