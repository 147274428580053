import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ApiGet, ApiPut, ApiDelete } from "../../../helpers/API/ApiData";
import { customStyles } from "../tableStyle";
import { TailSpin } from "react-loader-spinner";
import "./vattenfall.scss";
import { toast } from "react-toastify";
import useDebounce from "../../../hooks/useDebounceHook";
import Moment from "react-moment";
import Swal from "sweetalert2";
import { Trash } from "react-feather";
import { Button } from "reactstrap";
import Select from "react-select";
import DateFilter from "../Stepper/DateFilter";
import "moment-timezone";
import { getUserInfo } from "../../../utils/user.util";
import CloseIcon from "../closicon";
import CustomCalendar from "../Calender";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

export default function CommonVatenFall({ leadType }) {
  const [getNewsData, setGetNewsData] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [close, setclose] = useState("");
  const [image, setimage] = useState("");
  const [fileType, setfileType] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 900);
  const [userName, setUserName] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [selectedUserName, setSelectedUserName] = useState([]);
  const [selectedProject, setSelectedProject] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [search, setSearch] = useState();
  const [newpage, setNewPage] = useState(1);
  const [userData, setUserData] = useState();
  const [modal, setModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [showUserData, setShowUserData] = useState();
  const [newLoading, setNewLoading] = useState(false);
  const [currentLead, setCurrentLead] = useState();
  const [assignOption, setAssignOption] = useState();
  const [filterStatus, setFilterStatus] = useState();
  const [calendarModal, setCalendarModal] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [allLeads, setAllLeads] = useState([]);
  const [type, setType] = useState("");
  const [commentModel, setCommentModel] = useState(false);
  const [lead, setLead] = useState("");
  const [commentspin, setCommentspin] = useState(false);
  const [vattenfalltype, setVattenfallType] = useState("project-pv");

  const userInfo = getUserInfo();
  const limit = 10;

  const assignLeadOption = [
    {
      value: true,
      label: "zugeteilt",
    },
    {
      value: false,
      label: "nicht zugeteilt",
    },
  ];

  const statusOption = [
    {
      value: "offen",
      label: "offen",
    },
    {
      value: "AUFMAß",
      label: "AUFMAß",
    },
    {
      value: "ZEITLICHE VERZÖGERUNG",
      label: "ZEITLICHE VERZÖGERUNG",
    },
    {
      value: "SPÄTER ANRUFEN",
      label: "SPÄTER ANRUFEN",
    },
    {
      value: "STORNO",
      label: "STORNO",
    },
    {
      value: "ANGEBOTSBESPRECHUNG",
      label: "ANGEBOTSBESPRECHUNG",
    },
    {
      value: "ANGEBOTSBESPRECHUNG 2",
      label: "ANGEBOTSBESPRECHUNG 2",
    },
    {
      value: "VERKAUFT",
      label: "VERKAUFT",
    },
    {
      value: "NICHT ERREICHT",
      label: "NICHT ERREICHT",
    },
    {
      value: "TERMINIERT (1)",
      label: "TERMINIERT (1)",
    },
    {
      value: "TERMINIERT (2)",
      label: "TERMINIERT (2)",
    },
    {
      value: null,
      label: "Null",
    },
  ];

  const headerText =
    leadType == "vattenfall"
      ? "Vattenfall Leads"
      : leadType == "vattenfall-messe"
      ? "Vattenfall Messe Leads"
      : leadType == "vattenfallPromo"
      ? "Vattenfall Promo Leads"
      : "";

  const newImagefuntion = (items) => {
    setimage(items);
    const extension = items.split(".").pop().toLowerCase();

    setfileType(extension);
    if (
      extension == "image" ||
      extension == "pdf" ||
      extension == "png" ||
      extension == "svg" ||
      extension == "jpeg" ||
      extension == "jpg"
    ) {
      setclose(!close);
    } else {
      var link = document.createElement("a");
      link.download = "name";
      link.href = items;
      link.click();
    }
  };

  const handleSelectRow = (row) => {
    if (allLeads.length == 0) {
      setAllLeads([row._id]);
    } else if (allLeads.includes(row._id)) {
      setAllLeads(allLeads.filter((item) => item !== row._id));
    } else {
      setAllLeads([...allLeads, row._id]);
    }
  };

  const handleApply = async (selectedOption, startDate, endDate) => {
    if (!startDate || !endDate) {
      handleChangeCommom("", "startDate");
    } else {
      const timezoneOffset = startDate.getTimezoneOffset() * 60000; // in milliseconds
      startDate = new Date(startDate - timezoneOffset);
      endDate = new Date(endDate - timezoneOffset);
      startDate = startDate.toISOString().split("T")[0];
      endDate = endDate.toISOString().split("T")[0];
      const params = { ...filterData, startDate: startDate, endDate };
      setFilterData(params);
      handleChangeCommom("", "", params);
    }
  };

  const upadteAssign = (row) => {
    setModal(!modal);
    setCurrentLead(row);
    const data = row?.assignUid?.map((item) => {
      return item?._id;
    });
    setSelectedUser(data);
    setShowUserData(row?.assignUid);
  };

  const handleOnAllSend = (sendType) => {
    setModal(true);
    setType(sendType);
  };

  const columns = [
    {
      name: (
        <></>
        // <input
        //   type="checkbox"
        //   // checked={selectedRows?.length === getNewsData?.length}
        //   onClick={(e) => handleSelectAllRows(e.target.checked)}
        // />
      ),
      cell: (row, index) => {
        return (
          <input
            type="checkbox"
            style={{ cursor: "pointer" }}
            // disabled={row.apiAdded}
            checked={allLeads.includes(row._id)}
            onChange={() => handleSelectRow(row)}
          />
        );
      },
      width: "60px",
    },
    {
      name: "SNo",
      cell: (row, index) => {
        return <p>{(page - 1) * countPerPage + index + 1}</p>;
      },
      width: "60px",
    },
    {
      name: "View",
      cell: (row, index) => {
        return (
          <p>
            {row?.status ? (
              <NavLink to={`/vattenfall-data?id=${row._id}`}>view</NavLink>
            ) : (
              "-"
            )}
          </p>
        );
      },
      width: "80px",
    },
    {
      name: <span>Vertriebspartner zuweisen</span>,
      minWidth: "250px",
      selector: (row) => (
        <div
          className="profileImage"
          style={{
            display: "flex",
            gap: "10px",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          {row.assignUid?.length ? (
            row.assignUid
              ?.map((item) => item.name)
              .join(", ")
              .slice(0, 20)
          ) : (
            <button
              className="assignButtondesign"
              onClick={() => {
                setModal(!modal);
                setCurrentLead(row);
              }}
            >
              Lead zuweisen
            </button>
          )}

          {row.assignUid?.length > 0 && (
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => upadteAssign(row)}
              style={{ cursor: "pointer" }}
            >
              <title />

              <g id="Complete">
                <g id="edit">
                  <g>
                    <path
                      d="M20,16v4a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V6A2,2,0,0,1,4,4H8"
                      fill="none"
                      stroke="#000000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />

                    <polygon
                      fill="none"
                      points="12.5 15.8 22 6.2 17.8 2 8.3 11.5 8 16 12.5 15.8"
                      stroke="#000000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                </g>
              </g>
            </svg>
          )}
        </div>
      ),
    },

    {
      name: <span>status</span>,
      width: "250px",
      selector: (row) => {
        const statusClass =
          row?.status == "offen" ||
          row?.status == "NICHT ERREICHT" ||
          row?.status == "ZEITLICHE VERZÖGERUNG"
            ? "new-deisgnpppppppppp"
            : row?.status == "STORNO" || row?.status == "ABSAGE"
            ? "new-deisgn-statusooooooooooo"
            : "newstatusppppppppppp";

        return (
          <div className={statusClass}>{row.status ? row.status : "-"}</div>
        );
      },
    },
    {
      name: <span>Datum </span>,
      minWidth: "200px",
      selector: (row) => (
        <div className="profileImage">
          {row.createdAt ? (
            <Moment
              format="DD-MM-YYYY , HH:mm:ss"
              tz="CET"
              date={row.createdAt}
            />
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      minWidth: "150px",
      name: "Postleitzahl",
      selector: (row) => (
        <div className="profileImage">{row.pLZ ? row.pLZ : "-"}</div>
      ),
    },
    userInfo?.role == "admin" && {
      name: <span>Stornogrund </span>,
      minWidth: "250px",
      selector: (row) => (
        <div className="profileImage">{row?.reason ? row?.reason : "-"}</div>
      ),
    },

    {
      name: <span>Unique Id </span>,
      width: "150px",
      selector: (row) => (
        <div className="profileImage">{row.userId ? row.userId : "-"}</div>
      ),
    },
    {
      name: <span>Benutzername </span>,
      width: "200px",
      selector: (row) => (
        <div className="profileImage">
          {row.benutzername ? row.benutzername : "-"}
        </div>
      ),
    },
    {
      name: "Anrede",
      width: "100px",
      selector: (row) => (
        <div className="profileImage">
          {row.contractions ? row.contractions : "-"}
        </div>
      ),
    },

    {
      name: "Vorname ",
      width: "200px",
      selector: (row) => (
        <div className="profileImage">{row.name ? row.name : "-"}</div>
      ),
    },

    {
      name: " Nachname",
      width: "200px",
      selector: (row) => (
        <div className="profileImage">{row.nachname ? row.nachname : "-"}</div>
      ),
    },
    {
      name: " Lead information",
      width: "200px",
      selector: (row) => (
        <div className="profileImage">{row?.comment ? row.comment : "-"}</div>
      ),
    },

    {
      minWidth: "300px",
      name: " Adresse",
      selector: (row) => (
        <div className="profileImage" style={{ "white-space": "normal" }}>
          {row.strabe ? row.strabe : "-"}
        </div>
      ),
    },

    {
      minWidth: "150px",
      name: "Ort",
      selector: (row) => (
        <div className="profileImage">{row.location ? row.location : "-"}</div>
      ),
    },
    {
      minWidth: "150px",
      name: "Lead origin lat",
      selector: (row) => (
        <div className="profileImage">
          {row?.userLocationCoordinates
            ? row?.userLocationCoordinates?.coordinates?.[1]
            : "-"}
        </div>
      ),
    },
    {
      minWidth: "150px",
      name: "Lead origin lng",
      selector: (row) => (
        <div className="profileImage">
          {row?.userLocationCoordinates
            ? row?.userLocationCoordinates?.coordinates?.[0]
            : "-"}
        </div>
      ),
    },

    {
      minWidth: "150px",
      name: "Kontakt",
      selector: (row) => (
        <div className="profileImage">{row.telephon ? row.telephon : "-"} </div>
      ),
    },

    {
      name: "E-mail",
      width: "300px",
      selector: (row) => (
        <div className="profileImage">{row.email ? row.email : "-"}</div>
      ),
    },
    {
      name: "Energieverbrauch",
      width: "150px",
      selector: (row) => (
        <div className="profileImage">
          {row.power_consumption ? row.power_consumption : "-"}
        </div>
      ),
    },
    {
      name: "Haustyp",
      width: "200px",
      selector: (row) => (
        <div className="profileImage">
          {row.housetype ? row.housetype : "-"}
        </div>
      ),
    },
    {
      name: "Dachtyp",
      width: "200px",
      selector: (row) => (
        <div className="profileImage">
          {row.satteldach ? row.satteldach : "-"}
        </div>
      ),
    },

    {
      name: "Dachbelegung",
      width: "200px",
      selector: (row) => (
        <div className="profileImage">{row.roofFelt ? row.roofFelt : "-"}</div>
      ),
    },
    {
      name: "Störfaktoren",
      minWidth: "250px",

      selector: (row) => (
        <div className="schornstein" style={{ "white-space": "normal" }}>
          {row.schornstein ? row.schornstein.toString() : "-"}
        </div>
      ),
    },
    {
      name: "Dachneigung",
      minWidth: "250px",

      selector: (row) => (
        <div className="schornstein" style={{ "white-space": "normal" }}>
          {row.dachneigung ? row.dachneigung : "-"}
        </div>
      ),
    },
    {
      name: "E-Auto",
      width: "200px",
      selector: (row) => (
        <div className="profileImage">
          {row.aktuellkeineAuto ? row.aktuellkeineAuto : "-"}
        </div>
      ),
    },
    {
      name: "Heizzentrale",
      width: "200px",
      selector: (row) => (
        <div className="profileImage">
          {row.Heizzentrale ? row.Heizzentrale : "-"}
        </div>
      ),
    },

    {
      name: "Wärmepumpe",
      minWidth: "300px",
      selector: (row) => (
        <div className="profileImage">{row.rooftype ? row.rooftype : "-"}</div>
      ),
    },

    {
      minWidth: "300px",
      name: "Erreichbarkeit",
      selector: (row) => (
        <div className="profileImage" style={{ "white-space": "normal" }}>
          {row.accessibility && row.accessibility !== "undefined"
            ? row.accessibility
            : "-"}
        </div>
      ),
    },

    {
      minWidth: "300px",
      name: "wichtige Informationen",
      selector: (row) => (
        <div className="profileImage" style={{ "white-space": "normal" }}>
          {row?.notizen ? row.notizen?.[0]?.text : "-"}

          {/* <svg
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              setCommentModel(true);
              setLead(row);
            }}
            style={{ cursor: "pointer", paddingLeft: "5px" }}
          >
            <title />

            <g id="Complete">
              <g id="edit">
                <g>
                  <path
                    d="M20,16v4a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V6A2,2,0,0,1,4,4H8"
                    fill="none"
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />

                  <polygon
                    fill="none"
                    points="12.5 15.8 22 6.2 17.8 2 8.3 11.5 8 16 12.5 15.8"
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </g>
              </g>
            </g>
          </svg> */}
        </div>
      ),
    },

    {
      name: "Unterschrift",
      selector: (row) => (
        <div onClick={() => newImagefuntion(row.photo)} className="profileFoto">
          View
        </div>
      ),
    },

    {
      minWidth: "250px",
      name: "Sales Patners",
      selector: (row) => (
        <div className="profileImage" style={{ "white-space": "normal" }}>
          {row?.patners?.length > 0
            ? row?.patners?.map((item) => item.name).join(",")
            : "-"}
        </div>
      ),
    },

    {
      minWidth: "150px",
      name: "Löschen",
      selector: (row) => (
        <div className="actionColumn">
          <Button
            size="sm"
            color="transparent"
            className="btn btn-icon"
            onClick={() => {
              handleConfirmDelete(row);
            }}
          >
            <Trash className="font-medium-2" />
          </Button>
        </div>
      ),
    },
  ].filter(Boolean);

  const handleDeleteNewsInfo = async (row) => {
    setLoading(true);
    let response = false;
    await ApiDelete(`straper/deleteVattenfall?id=${row?._id}`)
      .then((res) => {
        setLoading(false);
        handleChangeCommom("", "", filterData);
        response = true;
      })
      .catch((err) => {
        setLoading(false);
      });
    return response;
  };

  const handleConfirmDelete = (row) => {
    return Swal.fire({
      title: "Bist du sicher?",
      text: "Sind Sie sicher, dass Sie den Lead löschen möchten?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ja, löschen",
      cancelButtonText: "Abbrechen",
      customClass: {
        confirmButton: "btn btn-primary mr-10",
        cancelButton: "btn btn-danger ms-1",
      },
      buttonsStyling: false,
    }).then(async function (result) {
      if (result.value) {
        const res = await handleDeleteNewsInfo(row);
        if (res) {
          Swal.fire({
            icon: "success",
            title: "Gelöscht!",
            text: "Die Lead wurde gelöscht.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        } else {
          Swal.fire({
            title: "Abgesagt",
            text: "Löschung abgebrochen!!",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Abgesagt",
          text: "Löschung abgebrochen!!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        Swal.fire({
          title: "Abgesagt",
          text: "Löschung abgebrochen!!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    });
  };

  const customStyle = {
    control: (provided, state) => ({
      ...provided,
      maxWidth: "200px",
      marginLeft: "20px",
      fontSize: "12px",
      border: state.isFocused ? "2px solid #73AA85 !important" : "",
      boxShadow: "1px solid #73AA85 !important",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "13px",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#73AA85" : "white",
      "&:hover": {
        backgroundColor: "#73AA85",
      },
    }),
    menu: (provided) => ({
      ...provided,
      maxWidth: "200px",
      marginLeft: "20px",
      zIndex: "999",
    }),
  };
  const ProjectStyle = {
    control: (provided, state) => ({
      ...provided,
      maxWidth: "150px",
      fontSize: "12px",
      marginLeft: "30px",
      border: state.isFocused ? "2px solid #73AA85 !important" : "",
      boxShadow: "1px solid #73AA85 !important",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "13px",
      color: state.isSelected ? "white" : "black", // Change selected color here
      backgroundColor: state.isSelected ? "#73AA85" : "white",
      "&:hover": {
        backgroundColor: "#73AA85",
      },
    }),
    menu: (provided) => ({
      ...provided,
      maxWidth: "150px",
      marginLeft: "30px",
      zIndex: "999",
    }),
  };

  useEffect(() => {
    handleChangeCommom("", "", filterData);
  }, [debouncedSearchValue, page, countPerPage, assignOption, filterStatus]);

  useEffect(() => {
    const handleGetSalesPatner = async () => {
      try {
        const response = await ApiGet(`user/get`);
        const data = response?.data?.payload?.data;
        if (data && Array.isArray(data)) {
          const optionData = data.map((item) => ({
            value: item?.userId,
            label: item?.name,
          }));

          setUserName(optionData);
        }
      } catch (error) {
        toast.error("Vertriebspartner konnten nicht abgerufen werden..");
      }
    };

    const handleGetProject = async () => {
      await ApiGet(`project/get`)
        .then((response) => {
          const data = response?.data?.payload.data;
          const optionData = data?.map((item) => ({
            value: item._id,
            label: item.projectname,
          }));
          setProjectOptions(optionData);
        })
        .catch((error) => {
          toast.error(error);
        });
    };
    handleGetSalesPatner();
    handleGetProject();
  }, []);

  const handleChangeCommom = async (e, key, filter) => {
    let updatedFilter = { ...filterData };

    if (!e && key) {
      if (key == "userId") {
        setSelectedUserName("");
      } else if (key == "projectName") {
        setSelectedProject("");
      }

      if (key === "startDate" || key === "endDate") {
        updatedFilter.startDate = "";
        updatedFilter.endDate = "";
      } else {
        updatedFilter[key] = "";
      }
    } else {
      if (key == "userId") {
        updatedFilter = {
          ...filterData,
          [`${key}[]`]: e.map((item) => item.value),
        };
      } else {
        updatedFilter = key ? { ...filterData, [key]: e.label } : filter;
      }
    }

    setLoading(true);
    let ddd = "";
    for (const [key, value] of Object.entries(updatedFilter)) {
      if (Array?.isArray(value)) {
        for (const v of value) {
          ddd += `${key}=${v}&`;
        }
      } else if (value) {
        const data = encodeURIComponent(value);
        ddd += `${key}=${data}&`;
      }
    }

    try {
      let uridata = encodeURIComponent(leadType);
      const response = await ApiGet(
        `straper/getVattenfall?${ddd}&funnel_type=${uridata}&limit=${countPerPage}&page=${page}&leadType=vattenfall-normal${
          debouncedSearchValue ? `&search=${searchValue}` : ""
        }${assignOption ? `&isAssign=${assignOption?.value}` : ""}${
          filterStatus ? `&status=${filterStatus?.value}` : ""
        }`
      );
      const data = response?.data?.payload.data;
      if (userInfo?.role == "vattenfallAdmin") {
        const stornoRecords = data.filter(
          (record) => record.status === "STORNO"
        );

        const half = Math.ceil(stornoRecords.length / 2);
        stornoRecords
          .slice(0, half)
          .forEach((record) => (record.status = "Aufmaß"));
        stornoRecords
          .slice(half)
          .forEach((record) => (record.status = "zeitliche Verzögerung"));
      }

      setCount(response?.data?.payload.count);
      if (key == "userId") {
        setSelectedUserName(e);
      } else if (key == "projectName") {
        setSelectedProject(e);
      }
      setGetNewsData(data);
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }

    setFilterData(updatedFilter);
  };

  const customNoDataComponent = () => (
    <div style={{ textAlign: "center", padding: "10px 0px", fontSize: "16px" }}>
      Aktuell sind keine Daten vorhanden.
    </div>
  );

  const getUserData = async () => {
    await ApiGet(
      `user/get?page=${newpage}&limit=${limit}${
        search ? `&search=${search}` : ""
      }`
    )
      .then((response) => {
        const data = response?.data?.payload.data;
        // setCount(response?.data?.payload.count);

        const activeData = data.filter((jobData) => {
          return jobData.roleId._id == "65fc2112e8fe93f708e798c3";
        });
        setUserData(activeData);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    getUserData();
  }, [newpage, limit, search]);

  const handlePage = () => {
    setNewPage((prev) => prev + 1);
  };

  const handleback = () => {
    setNewPage((prev) => prev - 1);
  };

  const handleCheckBox = (item, id) => {
    setSelectedUser((prevUserPostal) => {
      if (!prevUserPostal || prevUserPostal.length === 0) {
        return [id];
      } else if (prevUserPostal.includes(id)) {
        return prevUserPostal.filter((item) => item !== id);
      } else {
        return [...prevUserPostal, id];
      }
    });
    setShowUserData((prevPostal) => {
      if (!prevPostal || prevPostal.length === 0) {
        return [item];
      } else if (prevPostal.find((postalItem) => postalItem._id === id)) {
        return prevPostal.filter((postalItem) => postalItem._id !== id);
      } else {
        return [...prevPostal, item];
      }
    });
  };

  const removeItem = (newitem) => {
    setShowUserData((prevData) => {
      if (prevData.find((postalItem) => postalItem._id === newitem?._id)) {
        return prevData.filter((postalItem) => postalItem._id !== newitem?._id);
      }
    });

    setSelectedUser((prevUserPostal) => {
      if (prevUserPostal.includes(newitem?._id)) {
        return prevUserPostal.filter((item) => item !== newitem?._id);
      }
    });
  };

  const assignLeadUser = async () => {
    setNewLoading(true);
    try {
      if (type == "multiSend") {
        const body = {
          uid: selectedUser?.length > 0 ? selectedUser : [],
          leads: allLeads?.length > 0 ? allLeads : [],
          vattenfallType: vattenfalltype,
        };
        let resp = await ApiPut(`vattenfall/addLeadMilUser`, body);

        setType("");
        setModal(!modal);
        setSelectedUser([]);
        setShowUserData("");
        toast.success("Zugewiesen");
        handleChangeCommom("", "", filterData);
        setAllLeads([]);
      } else {
        const body = {
          assignUid: selectedUser?.length > 0 ? selectedUser : [],
          vattenfallType: vattenfalltype,
        };
        let resp = await ApiPut(
          `vattenfall/assignLead?id=${currentLead?._id}`,
          body
        );
        toast.success("Zugewiesen");
        setModal(!modal);
        setSelectedUser([]);
        setShowUserData("");
        handleChangeCommom("", "", filterData);
      }
    } catch (error) {
      console.log(error);
      toast.error("jemand anderes arbeitet bereits an diesem Hinweis");
    } finally {
      setNewLoading(false);
    }
  };

  const handleChangeAssign = (data, type) => {
    switch (type) {
      case "isAssign":
        setAssignOption(data);
        break;
      case "status":
        setFilterStatus(data);
        break;
    }
  };

  const updateLeadComment = async () => {
    try {
      setCommentspin(true);
      const body = {
        notizen: lead?.notizen,
      };
      const response = await ApiPut(
        `vattenfall/updateLeadVatten?id=${lead?._id}`,
        body
      );
      handleChangeCommom("", "", filterData);
      toast.success("Aktualisiert");
      setCommentModel(false);
      setLead("");
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    } finally {
      setCommentspin(false);
    }
  };

  const deletMultipleLead = async () => {
    const payload = {
      id: allLeads,
    };
    try {
      await ApiDelete(`vattenfall/delete-multiple-lead`, payload);
      setAllLeads([]);
      handleChangeCommom("", "", filterData);
    } catch (error) {
      console.error("error:", error);
    }
  };

  return (
    <div>
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 px-3 wattfox-header-alignment">
            <div className=" col-lg-2 d-flex align-items-center gap-20 mobile-view-alignment-main-parent-title">
              <h2 className="pt-2 mr-5">{headerText}</h2>
            </div>

            <div className="col-lg-8 mobile-view-alignment-main-parent">
              <div className="row mobile-view-alignment mobile-view-alignment-main">
                {userInfo?.role != "vattenfallAdmin" && (
                  <div>
                    <DateFilter
                      onApply={handleApply}
                      className="Datefilter-flx-mobile-main"
                    />
                  </div>
                )}

                {userInfo?.role != "vattenfallAdmin" && (
                  <>
                    {" "}
                    <div>
                      <div className="project-Dropdown project-Dropdown-sc">
                        <Select
                          className="Select-main"
                          value={assignOption}
                          name="isAssign"
                          onChange={(e) => handleChangeAssign(e, "isAssign")}
                          options={assignLeadOption}
                          styles={ProjectStyle}
                          defaultValue={assignOption}
                          placeholder="zugeteilt"
                          isClearable={true}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="project-Dropdown project-Dropdown-sc">
                        <Select
                          className="Select-main"
                          value={filterStatus}
                          name="status"
                          onChange={(e) => handleChangeAssign(e, "status")}
                          options={statusOption}
                          styles={ProjectStyle}
                          defaultValue={filterStatus}
                          placeholder="Status"
                          isClearable={true}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="project-Dropdown">
                        <Select
                          className="Select-main"
                          value={selectedProject}
                          name="projectName"
                          onChange={(e) => handleChangeCommom(e, "projectName")}
                          options={projectOptions}
                          styles={ProjectStyle}
                          defaultValue={selectedProject}
                          placeholder="Projekt "
                          isClearable={true}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="project-Dropdown">
                        <Select
                          className="dropdown-align "
                          value={selectedUserName}
                          name="userName"
                          onChange={(e) => {
                            handleChangeCommom(e, "userId");
                          }}
                          placeholder="Vertriebspartner"
                          options={userName}
                          maxHeight={"40px"}
                          defaultValue={selectedUserName}
                          isClearable={true}
                          isMulti
                          styles={customStyle}
                        />
                      </div>
                    </div>{" "}
                  </>
                )}

                {userInfo?.role != "vattenfallAdmin" && (
                  <div>
                    <div>
                      <input
                        type="search"
                        className={`form-control form-control-lg form-control-solid `}
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                          setLoading(true);
                        }}
                        name="search"
                        value={searchValue}
                        placeholder="Suche"
                      />
                    </div>
                  </div>
                )}
                <div
                  className={
                    allLeads?.length > 0
                      ? "all-lead-butoon mobile-view-serch "
                      : "all-lead-butoon disable mobile-view-serch"
                  }
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "10px",
                    paddingRight: "10px",
                  }}
                >
                  <button
                    onClick={() => handleOnAllSend("multiSend")}
                    disabled={allLeads?.length == 0}
                  >
                    Lead zuweisen
                  </button>
                </div>
                <div
                  className={
                    allLeads?.length > 0
                      ? "all-lead-butoon mobile-view-serch "
                      : "all-lead-butoon disable mobile-view-serch"
                  }
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "10px",
                    paddingRight: "10px",
                  }}
                >
                  <button
                    onClick={() => deletMultipleLead()}
                    disabled={allLeads?.length == 0}
                  >
                    Lead löschen
                  </button>
                </div>
              </div>
            </div>
          </div>

          <DataTable
            data={getNewsData}
            columns={columns}
            responsive
            noDataComponent={customNoDataComponent()}
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            className="new_data__table table_height"
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[5, 10, 20, 25, 50]}
            paginationDefaultPage={page}
            progressPending={loading}
            progressComponent={
              <div className="LoadinComponent">
                <TailSpin color="#334D52" height={30} width={30} />
              </div>
            }
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
            fixedHeader
          />
        </div>
      </div>
      {close && (
        <div className="new__imagemodel">
          <div className="body_model"></div>
          <div className="new-white-box">
            <div className="header_close">
              <i
                onClick={() => {
                  setclose(!close);
                  setimage("");
                }}
                className="fas fa-window-close modalClose"
              ></i>
            </div>
            <div className="image_body">
              {fileType == "svg" ||
              fileType == "png" ||
              fileType == "jpg" ||
              fileType == "jpeg" ? (
                <>
                  <img className="modal-image" src={image} />
                </>
              ) : fileType === "pdf" ? (
                <>
                  <object
                    data={image}
                    type="application/pdf"
                    width="420px"
                    height="650px"
                  ></object>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}

      {modal ? (
        <div className="edit-drop-down-main">
          <div className="edit-drop-down-content-main">
            <div className="edit-drop-down-content">
              <div className="header-text">Vertriebspartner</div>
              <div style={{ cursor: "pointer" }}>
                <svg
                  onClick={() => {
                    setModal(!modal);
                    setSearch("");
                    setSelectedUser([]);
                    setShowUserData();
                    setCalendarModal(false);
                  }}
                  style={{ cursor: "pointer" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke={"#000"}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </div>
            </div>

            <div className="edit-drop-down-body">
              <div className="input-search">
                <input
                  type="text"
                  placeholder="Suche"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                  margin: "10px 0 10px 10px",
                }}
              >
                <div
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    onChange={() => setVattenfallType("project-pv")}
                    checked={vattenfalltype == "project-pv"}
                  />
                  <label style={{ margin: "0px" }}>Projekte PV</label>
                </div>
                <div
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    onChange={() => setVattenfallType("project-wp")}
                    checked={vattenfalltype == "project-wp"}
                  />
                  <label style={{ margin: "0px" }}>Projekte WP</label>
                </div>
              </div>

              <div className="postalcode-model-design">
                <div className="modalPlzAlign">
                  {Array.isArray(showUserData) &&
                    showUserData.length > 0 &&
                    showUserData?.map((item) => {
                      return (
                        <p>
                          {item?.name}{" "}
                          <CloseIcon
                            stroke={"#fff"}
                            onClick={removeItem}
                            id={item}
                          />
                        </p>
                      );
                    })}
                </div>
              </div>

              {Array.isArray(userData) && userData.length > 0 ? (
                userData.map((item) => (
                  <div className="checckbox-alignmnent" key={item._id}>
                    <input
                      type="checkbox"
                      checked={selectedUser?.includes(item._id)}
                      onChange={() => handleCheckBox(item, item._id)}
                    />
                    <p>{item?.name}</p>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "40px",
                  }}
                >
                  <p>Aktuell sind keine Daten vorhanden.</p>{" "}
                </div>
              )}
            </div>

            <div className="edit-drop-down-pagination">
              <div style={{ display: "flex", gap: "10px" }}>
                <button onClick={() => assignLeadUser()}>
                  {newLoading ? (
                    <TailSpin
                      color="#ffffff"
                      height={22}
                      width={22}
                      ariaLabel="loading"
                    />
                  ) : (
                    "Speichern"
                  )}
                </button>

                {!allLeads?.length && selectedUser?.length == 1 && (
                  <button
                    onClick={() => {
                      setCalendarModal(true);
                      setModal(!modal);
                    }}
                  >
                    {newLoading ? (
                      <TailSpin
                        color="#ffffff"
                        height={22}
                        width={22}
                        ariaLabel="loading"
                      />
                    ) : (
                      "nächste"
                    )}
                  </button>
                )}
              </div>
              <div className="butoon-alignment">
                {newpage !== 1 && (
                  <div className="previus" onClick={handleback}>
                    <svg
                      viewBox="0 0 256 512"
                      width="20"
                      height="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" />
                    </svg>
                  </div>
                )}

                <div className="next" onClick={handlePage}>
                  <svg
                    viewBox="0 0 256 512"
                    width="20"
                    height="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : calendarModal ? (
        <div className="model-design-meeting">
          <div className="model-design-content">
            <div
              className="close-icon"
              onClick={() => {
                setCalendarModal(false);
                setSelectedUser([]);
                setShowUserData();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z"
                  fill="#000"
                />
              </svg>
            </div>
            <CustomCalendar
              ownMessage={""}
              setModal={setModal}
              modal={modal}
              leadStatus={"TERMINIERT (1)"}
              leadData={currentLead}
              selectedUser={selectedUser}
              meetingDate={null}
              appoinmentData={[]}
              type={"add"}
              assignLeadUser={assignLeadUser}
              loadings={loadings}
              setLoadings={setLoadings}
            />
          </div>
        </div>
      ) : (
        <></>
      )}

      {commentModel && (
        <div className="dropdown-modals">
          <div className="dropdown-modal-mds">
            <div className="header-aligns">
              <div className="headfer-designs">
                <p>wichtige Informationen</p>
              </div>
              <div className="close-icons">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                  fill="none"
                  onClick={() => {
                    setCommentModel(false);
                    setLead("");
                  }}
                >
                  <path
                    d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z"
                    fill="#000"
                  />
                </svg>
              </div>
            </div>
            <div className="body-part-datas">
              <label>
                wichtige Informationen
                <span style={{ color: "red" }}>*</span>
              </label>
              <textarea
                placeholder="wichtige Informationen"
                style={{ height: "150px", padding: "10px" }}
                value={lead?.notizen}
                onChange={(e) => setLead({ ...lead, notizen: e.target.value })}
              />
            </div>

            <div
              className={
                !lead?.notizen || lead?.notizen?.trim() == ""
                  ? "modl-dfot-designs disable"
                  : "modl-dfot-designs"
              }
              style={{ display: "flex", gap: "10px" }}
            >
              <button
                onClick={updateLeadComment}
                disabled={!lead?.notizen || lead?.notizen?.trim() == ""}
              >
                {commentspin ? (
                  <TailSpin color="#FFF" height={15} width={15} />
                ) : (
                  "speichern"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
