export const options = {

    housetype: [
        { label: "Einfamilienhaus", value: "Einfamilienhaus" },
        { label: "Reihenhaus", value: "Reihenhaus" },
        { label: "Doppelhaushälfte", value: "Doppelhaushälfte" },
        { label: "Mehrfamilienhaus", value: "Mehrfamilienhaus" },
        { label: "Garage", value: "Garage" },
        { label: "Anderes", value: "Anderes" },
    ],


    satteldach: [
        { label: "Satteldach", value: "Satteldach" },
        { label: "Flachdach", value: "Flachdach" },
        { label: "Walmdach", value: "Walmdach" },
        { label: "Pultdach", value: "Pultdach" },
        { label: "Anderes", value: "Anderes" },
    ],


    roofFelt: [
        { label: "Dachziegel", value: "Dachziegel" },
        { label: "Dachpappe", value: "Dachpappe" },
        { label: "Wellblech", value: "Wellblech" },
        { label: "Trapezblech", value: "Trapezblech" },
        { label: "Unklar", value: "Unklar" },
        { label: "Anderes", value: "Anderes" },
    ],


    schornstein: [
        { label: "Gaube", value: "Gaube" },
        { label: "Dachfenster", value: "Dachfenster" },
        { label: "Schornstein", value: "Schornstein" },
        { label: "Solarthermie", value: "Solarthermie" },
        { label: "Bäume", value: "Bäume" },
        { label: "Keine", value: "Keine" },
    ],


    aktuellkeineAuto: [
        { label: "E-Auto vorhanden", value: "E-Auto vorhanden" },
        { label: "E-Auto geplant", value: "E-Auto geplant" },
        { label: "Aktuell kein E-Auto", value: "Aktuell kein E-Auto" },
        { label: "Kein E-Auto geplant", value: "Kein E-Auto geplant" },
    ],


    rooftype: [
        { label: "Wärmepumpe vorhanden", value: "Wärmepumpe vorhanden" },
        { label: "Wärmepumpe geplant", value: "Wärmepumpe geplant" },
        { label: "Keine Wärmepumpe gewünscht", value: "Keine Wärmepumpe gewünscht" },
        { label: "Keine Auskunft", value: "Keine Auskunft" },
    ],

}
