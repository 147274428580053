import React from "react";
import { ContentRoute } from "../../_metronic/layout";
import DashboardPage from "../pages/DashboardPage";
import LeadAdminWattFox from "../../_metronic/components/LeadAdmin/leadAdminWattFox";
import LeadAdminVattenFall from "../../_metronic/components/LeadAdmin/leadAdminVattenFall";
import LeadAdminVattenFallMesse from "../../_metronic/components/LeadAdmin/leadAdminVattenFallMesse";
import LeadAdminVattenFallPromo from "../../_metronic/components/LeadAdmin/leadAdminVattenFallPromo";
import GoogleMap from "../../_metronic/components/GoogleMap";
import VattenfallLaufles from "../../_metronic/components/VattenfallLaufls";
import WattFoxLaufliste from "../../_metronic/components/WattfoxLaufliste";
import VattenFallLeadData from "../../_metronic/components/VattenFallLeadData/index.jsx";

const LeadAdminRoutes = () => {
  return (
    <>
      <ContentRoute exact path="/dashboard" component={DashboardPage} />
      <ContentRoute exact path="/stepper" component={LeadAdminWattFox} />

      <ContentRoute exact path="/vattenfall" component={LeadAdminVattenFall} />
      <ContentRoute
        exact
        path="/vattenfallmesse"
        component={LeadAdminVattenFallMesse}
      />
      <ContentRoute
        exact
        path="/vattenfallpromo"
        component={LeadAdminVattenFallPromo}
      />
      <ContentRoute
        exact
        path="/vattenfall-data"
        component={VattenFallLeadData}
      />

      <ContentRoute exact path="/google-map" component={GoogleMap} />
      <ContentRoute
        exact
        path="/vattenfall-laufles"
        component={VattenfallLaufles}
      />
      <ContentRoute
        exact
        path="/wattfox-laufles"
        component={WattFoxLaufliste}
      />
    </>
  );
};

export default LeadAdminRoutes;
