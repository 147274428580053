import React from "react";

const EditIconSvg = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 32 32"
    >
      <path
        d="M0 0 C1.28132812 0.00902344 2.56265625 0.01804687 3.8828125 0.02734375 C4.87023438 0.03894531 5.85765625 0.05054688 6.875 0.0625 C6.545 1.3825 6.215 2.7025 5.875 4.0625 C1.915 4.0625 -2.045 4.0625 -6.125 4.0625 C-5.795 10.3325 -5.465 16.6025 -5.125 23.0625 C1.145 23.0625 7.415 23.0625 13.875 23.0625 C14.205 19.1025 14.535 15.1425 14.875 11.0625 C15.865 11.0625 16.855 11.0625 17.875 11.0625 C17.90185095 13.54173801 17.92176547 16.0206884 17.9375 18.5 C17.94587891 19.20705078 17.95425781 19.91410156 17.96289062 20.64257812 C17.97153588 22.44943559 17.92720011 24.25637603 17.875 26.0625 C16.12954395 27.80795605 13.71375783 27.1927316 11.34375 27.1953125 C10.22355469 27.19660156 9.10335938 27.19789062 7.94921875 27.19921875 C6.76972656 27.19535156 5.59023437 27.19148438 4.375 27.1875 C2.60576172 27.19330078 2.60576172 27.19330078 0.80078125 27.19921875 C-0.87951172 27.19728516 -0.87951172 27.19728516 -2.59375 27.1953125 C-3.62951172 27.19418457 -4.66527344 27.19305664 -5.73242188 27.19189453 C-8.125 27.0625 -8.125 27.0625 -9.125 26.0625 C-9.21237645 24.20880389 -9.23198153 22.35182459 -9.22265625 20.49609375 C-9.21943359 19.37138672 -9.21621094 18.24667969 -9.21289062 17.08789062 C-9.20451172 15.90388672 -9.19613281 14.71988281 -9.1875 13.5 C-9.18298828 12.31212891 -9.17847656 11.12425781 -9.17382812 9.90039062 C-9.16199675 6.95437774 -9.14551287 4.00846319 -9.125 1.0625 C-6.12186252 -0.43906874 -3.33432478 -0.03031204 0 0 Z "
        fill="#000000"
        transform="translate(9.125,4.9375)"
      />
      <path
        d="M0 0 C2.62187494 1.04874998 3.79371361 1.64931313 5.25 4.125 C5.4975 4.74375 5.745 5.3625 6 6 C4.49907226 7.66400641 2.97327285 9.3055983 1.4375 10.9375 C0.59058594 11.85402344 -0.25632812 12.77054688 -1.12890625 13.71484375 C-4.49747899 16.39595267 -6.7581106 16.75356143 -11 17 C-10.6545369 11.05362445 -9.2855519 8.58032011 -4.9375 4.5625 C-4.01839844 3.69753906 -3.09929688 2.83257812 -2.15234375 1.94140625 C-1.08693359 0.98041016 -1.08693359 0.98041016 0 0 Z "
        fill="#000000"
        transform="translate(22,4)"
      />
      <path
        d="M0 0 C1.66611905 -0.042721 3.33382885 -0.04063832 5 0 C6 1 6 1 6.0625 3.5625 C6.041875 4.366875 6.02125 5.17125 6 6 C4.02 6.99 4.02 6.99 2 8 C0.68 6.68 -0.64 5.36 -2 4 C-1.34 2.68 -0.68 1.36 0 0 Z "
        fill="#000000"
        transform="translate(26,0)"
      />
    </svg>
  );
};

export default EditIconSvg;
