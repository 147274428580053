import React from "react";
import { ContentRoute } from "../../_metronic/layout/index.js";
import DashboardPage from "../pages/DashboardPage.js";
import ProjectTrello from "../../_metronic/components/ProjektTrello/index.js";
import VattenfallLeads from "../../_metronic/components/vattenfallLeads/index.jsx";
import VattenfallLeadsMesse from "../../_metronic/components/vattenfallMesse/index.jsx";
import VattenfallPromoLeads from "../../_metronic/components/vattenfallPromo/index.jsx";
import CredentialManagement from "../../_metronic/components/CredentialManagement/CredentialManagement.jsx";
import Project from "../../_metronic/components/ProjectName/Project.jsx";
import ProjeckteWp from "../../_metronic/components/prokectWp/index.jsx";

const OldVattenFallRoutes = () => {
  return (
    <>
      {" "}
      <ContentRoute exact path="/dashboard" component={DashboardPage} />
      <ContentRoute exact path="/projekte-trello" component={ProjectTrello} />
      <ContentRoute exact path="/projektewp" component={ProjeckteWp} />
      <ContentRoute exact path="/vattenfall" component={VattenfallLeads} />
      <ContentRoute
        exact
        path="/vattenfallmesse"
        component={VattenfallLeadsMesse}
      />
      <ContentRoute
        exact
        path="/vattenfallpromo"
        component={VattenfallPromoLeads}
      />
      <ContentRoute exact path="/credential" component={CredentialManagement} />
      <ContentRoute exact path="/project" component={Project} />
    </>
  );
};

export default OldVattenFallRoutes;
