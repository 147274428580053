import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Input, Label } from "reactstrap";
import Select from "react-select";
import { options } from "./options";
import { ApiPut } from "../../../../../helpers/API/ApiData";
import { toast } from "react-toastify";

const LeadModel = ({ lead, handleModel, handleGetDashboardCount, setZoom }) => {
  console.log("lead", lead);
  const [updatedData, setUpdatedData] = useState(lead);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData({ ...updatedData, [name]: value });
  };
  useEffect(() => {
    setModelValue();
  }, []);
  const setModelValue = () => {
    const arrayData = updatedData?.schornstein?.map((item) => {
      const obj = {
        value: item,
        label: item,
      };
      return obj;
    });

    const payload = {};

    for (const [key, value] of Object.entries(updatedData)) {
      const keyArray = [
        "housetype",
        "satteldach",
        "roofFelt",
        "aktuellkeineAuto",
        "rooftype",
      ];
      if (keyArray.includes(key)) {
        payload[key] = { value: value, label: value };
      }
    }

    setUpdatedData({ ...updatedData, ...payload, schornstein: arrayData });
  };

  const handleSelect = (data, name) => {
    setUpdatedData({ ...updatedData, [name]: data });
  };
  console.log("updatedData", updatedData);
  const updloadData = async () => {
    try {
      let payload = {};
      for (const [key, value] of Object.entries(updatedData)) {
        console.log("value========", value);
        if (
          key !== "locationCoordinates" &&
          key !== "userLocationCoordinates"
        ) {
          if (Array.isArray(value)) {
            const data = value.map((item) => item.value);
            payload[key] = data;
          } else if (
            value &&
            typeof value === "object" &&
            value.label &&
            value.value
          ) {
            payload[key] = value.value;
          } else {
            payload[key] = value;
          }
        }
      }

      await ApiPut(`straper/updateStraperLead?id=${updatedData._id}`, payload);

      toast.success("sucessfully updated");
      await handleModel();
      await handleGetDashboardCount();
      await handleModel(payload);
    } catch (error) {
      console.error("Something went wrong!");
    }
  };

  return (
    <>
      <Modal.Header
        className="justify-content-start d-flex align-items-center"
        closeButton
      >
        <h1> Lead Information </h1>
      </Modal.Header>
      <Modal.Body>
        <div class="row pb-3">
          <div className="col-4 col-md-4">
            <Label className="form-label" for="name">
              Vorname
            </Label>
            <Input
              placeholder="Benutzername"
              name="name"
              value={updatedData?.name}
              onChange={(e) => handleOnChange(e)}
            />
          </div>
          <div className="col-4 col-md-4">
            <Label className="form-label" for="nachname">
              Nachname
            </Label>
            <Input
              placeholder="Nachname"
              name="nachname"
              value={updatedData?.nachname}
              onChange={(e) => handleOnChange(e)}
            />
          </div>
          <div className="col-4 col-md-4">
            <Label className="form-label" for="Strabe">
              Strabe
            </Label>
            <Input
              placeholder="Strabe"
              name="strabe"
              value={updatedData?.strabe}
              onChange={(e) => handleOnChange(e)}
            />
          </div>
        </div>
        <div class="row pb-3">
          <div className="col-4 col-md-4">
            <Label className="form-label" for="name">
              Ort
            </Label>
            <Input
              placeholder="Ort"
              name="location"
              value={updatedData?.location}
              onChange={(e) => handleOnChange(e)}
            />
          </div>
          <div className="col-4 col-md-4">
            <Label className="form-label" for="pLZ">
              PLZ
            </Label>
            <Input
              placeholder="PLZ"
              name="pLZ"
              value={updatedData?.pLZ}
              onChange={(e) => handleOnChange(e)}
            />
          </div>
          <div className="col-4 col-md-4">
            <Label className="form-label" for="telephon">
              Telephone
            </Label>
            <Input
              placeholder="Telephone"
              name="telephon"
              value={updatedData?.telephon}
              onChange={(e) => handleOnChange(e)}
            />
          </div>
        </div>
        <div class="row pb-3">
          <div className="col-4 col-md-4">
            <Label className="form-label" for="email">
              E-Mail
            </Label>
            <Input
              placeholder="E-Mail"
              name="email"
              value={updatedData?.email}
              onChange={(e) => handleOnChange(e)}
            />
          </div>
          <div className="col-4 col-md-4">
            <Label className="form-label" for="Stromverbrauch">
              Stromverbrauch
            </Label>
            <Input
              placeholder="Stromverbrauch"
              name="power_consumption"
              value={updatedData?.power_consumption}
              onChange={(e) => handleOnChange(e)}
            />
          </div>
          <div className="col-4 col-md-4">
            <Label className="form-label" for="Einfamilienhaus">
              Einfamilienhaus
            </Label>
            <div>
              <Select
                className="Select-main"
                options={options["housetype"]}
                placeholder="Einfamilienhaus"
                value={updatedData?.housetype}
                name="housetype"
                onChange={(e) => handleSelect(e, "housetype")}
              />
            </div>
          </div>
        </div>
        <div class="row pb-3">
          <div className="col-4 col-md-4">
            <Label className="form-label" for="Satteldach">
              Satteldach
            </Label>
            <div>
              <Select
                className="Select-main"
                options={options["satteldach"]}
                placeholder="Satteldach"
                value={updatedData?.satteldach}
                name="satteldach"
                onChange={(e) => handleSelect(e, "satteldach")}
              />
            </div>
          </div>

          <div className="col-4 col-md-4">
            <Label className="form-label" for="roofFelt">
              Dachziegel
            </Label>
            <div>
              <Select
                className="Select-main"
                options={options["roofFelt"]}
                placeholder="Dachziegel"
                value={updatedData?.roofFelt}
                name="roofFelt"
                onChange={(e) => handleSelect(e, "roofFelt")}
              />
            </div>
          </div>

          <div className="col-4 col-md-4">
            <Label className="form-label" for="schornstein">
              Gaube
            </Label>
            <div>
              <Select
                className="Select-main"
                options={options["schornstein"]}
                placeholder="Gaube"
                isMulti
                value={updatedData?.schornstein}
                name="schornstein"
                onChange={(e) => handleSelect(e, "schornstein")}
              />
            </div>
          </div>
        </div>

        <div class="row pb-3">
          <div className="col-4 col-md-4">
            <Label className="form-label" for="E-Auto vorhanden">
              E-Auto vorhanden
            </Label>
            <div>
              <Select
                className="Select-main"
                options={options["aktuellkeineAuto"]}
                placeholder="E-Auto vorhanden"
                value={updatedData?.aktuellkeineAuto}
                name="aktuellkeineAuto"
                onChange={(e) => handleSelect(e, "aktuellkeineAuto")}
              />
            </div>
          </div>
          <div className="col-4 col-md-4">
            <Label className="form-label" for="roofFelt">
              Wärmepumpe vorhanden
            </Label>
            <div>
              <Select
                className="Select-main"
                options={options["rooftype"]}
                placeholder="Wärmepumpe vorhanden"
                value={updatedData?.rooftype}
                name="rooftype"
                onChange={(e) => handleSelect(e, "rooftype")}
              />
            </div>
          </div>

          <div className="col-4 col-md-4">
            <Label className="form-label" for="wichtige Informationen">
              wichtige Informationen
            </Label>
            <Input
              name="notizen"
              placeholder="wichtige Informationen"
              value={updatedData?.notizen}
              onChange={(e) => handleOnChange(e)}
            />
          </div>
        </div>
        <div>
          <div className="col-4 col-md-4">
            <Label className="form-label" for="wichtige Informationen">
              comment
            </Label>
            <Input
              name="comment"
              placeholder="comment"
              value={updatedData?.comment}
              onChange={(e) => handleOnChange(e)}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="activebutton h-40px"
          style={{ marginRight: "15px" }}
          onClick={handleModel}
        >
          Abbrechen
        </Button>
        <Button className="addbutton" onClick={updloadData}>
          <span>{"update"}</span>
        </Button>
      </Modal.Footer>
    </>
  );
};

export default LeadModel;
